import { sendToDataLayer, getDefaultEventCategory, PageSection } from '@utils';

export const trackProvinceSelect = (provinceName: string) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.CarWithDriver),
    event_action: 'select_topmenu',
    event_label: `province_${provinceName}`
  });
};

export const trackSelectCar = (carModel: string) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.CarWithDriver),
    event_action: 'select_topmenu',
    event_label: `car_${carModel.toLowerCase().replace(/ /g, '_')}`
  });
};
