import { PureComponent } from 'react';
import CarCard from './card';
import { City } from '../../../../../templates/car-with-driver/interface';
import { trackProvinceSelect } from './tracking';
import './style.scss';
export interface CarListProps {
  cities: City[];
  prefix: string;
  onSelectCar: (carID: number, carType: string, cityID: string) => void;
}

interface CarListState {
  city: City;
  cityID: number;
}
class CarList extends PureComponent<CarListProps, CarListState> {
  state: CarListState = {
    city: this.props.cities[0],
    cityID: this.props.cities[0].id
  };

  onClickCar = (carID: number, carType: string) => {
    this.props.onSelectCar(carID, carType, this.state.cityID.toString());
    const formRef = document.getElementById('driver-booking-form');
    if (!formRef) return;
    formRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  handleOnSelectProvince = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { cities, prefix } = this.props;
    this.setState(
      {
        cityID: Number(e.currentTarget.value),
        city: cities.filter((city) => city.id == Number(e.currentTarget.value))[0]
      },
      () => trackProvinceSelect(this.state.city.name[prefix])
    );
  };

  render() {
    const { cities, prefix } = this.props;
    const { cityID, city } = this.state;
    return (
      <div id="car-list">
        <div className="container">
          <h2>รุ่นรถที่พร้อมให้บริการ เช่ารถ พร้อมคนขับ</h2>
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="form-group">
                <label>สถานที่รับรถ</label>
                <div className="selectContainer">
                  <select
                    className="form-control"
                    id="FormControlSelect1"
                    value={cityID}
                    onChange={(e) => this.handleOnSelectProvince(e)}
                  >
                    {cities.map((city) => (
                      <option key={city.name[prefix]} value={city.id}>
                        {city.name[prefix]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {/* end row */}

          <div className="wrapper">
            <div className="card-widget">
              <div className="card-container card-container--cwd car scrollable">
                {city.cars.map((car) => (
                  <CarCard
                    key={car.id}
                    car={car}
                    prefix={prefix}
                    onSelectCar={(carID) => this.onClickCar(carID, car.category['th'])}
                  />
                ))}
              </div>
            </div>
            {/* end card-container */}
          </div>
        </div>
      </div>
    );
  }
}

export default CarList;
