import { FunctionComponent } from 'react';

export const RentalStep: FunctionComponent = () => {
  return (
    <div
      id="how-to-book"
      className="banner-container"
      style={{ backgroundImage: 'url(/assets/cms/how-to-book-driver.webp)' }}
    >
      <div className="overlay" />
      <div className="container">
        <div className="content-section">
          <h2>ขั้นตอนง่ายๆในการใช้บริการเช่ารถ พร้อมคนขับ</h2>
          <div className="item-wrap">
            <div className="item">
              <span className="badge badge-pill badge-primary">1</span> เลือกจังหวัด - วันเวลาใช้รถ
            </div>
            <div className="item">
              <span className="badge badge-pill badge-primary">2</span> เลือกประเภทรถ - กดจอง
            </div>
            <div className="item">
              <span className="badge badge-pill badge-primary">3</span> รอร้านรถเช่าติดต่อกลับ
            </div>
            <div className="item">
              <span className="badge badge-pill badge-primary">4</span> ยืนยันการจอง
            </div>
            <div className="line"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentalStep;
