import { PureComponent, Fragment } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Header from '@pages/car-with-driver/sections/header';
import Layout from '@shares/layout';
import SEO from '@shares/seo';
import axios from 'axios';
import { withTrans } from '@shares/locales/hoc';
import { CarWithDriverProps, CarWithDriverState, QueryResult } from './interface';
import { FormSubmitValues } from '@pages/car-with-driver/sections/form/interface';
import { API_CAR_WITH_DRIVER } from '@config';
import LazyLoad from 'react-lazyload';
import Context, { ContextType } from '@ui/components/context';
import CarList from '@pages/car-with-driver/sections/car-list';
import SuccessBox from '@pages/car-with-driver/sections/form/success';
import Form from '@pages/car-with-driver/sections/form';
import { trackFormSubmit, CWDFormStatus } from '@pages/car-with-driver/sections/form/tracking';
import Loading from '@shares/loading';
import ValueProps from '@pages/car-with-driver/sections/values-props';
import RentalStep from '@pages/car-with-driver/sections/rental-step';
import Services from '@shares/services';
import Regions from '@shares/regions';
import FAQ from '@shares/faq';
import { PageSection } from '@utils';
import './style.scss';

axios.defaults.baseURL = API_CAR_WITH_DRIVER;

// eslint-disable-next-line no-undef
const CarWithDriverPage = (props: PageWithTranslationProps) => (
  <StaticQuery
    query={graphql`
      query {
        carWithDriver: allMarkdownRemark(filter: { fields: { collection: { eq: "carwithdriver" } } }) {
          edges {
            node {
              frontmatter {
                header_th
                header_en
                title_th
                title_en
                keywords_th
                keywords_en
                description_th
                description_en
                sub_title_th
                sub_title_en
                seo_header_th
                seo_header_en
                panels {
                  title_th
                  title_en
                  content_th
                  content_en
                  show_google_faq
                }
              }
            }
          }
        }
      }
    `}
    render={(data: QueryResult) => <Index {...props} data={data} />}
  />
);
interface Panels {
  title: string;
  content: string;
  show_google_faq: boolean;
}
class Index extends PureComponent<CarWithDriverProps, CarWithDriverState> {
  state = {
    isLoading: true,
    cities: [],
    isSuccess: false,
    response: {
      full_name: '',
      phone_number: '',
      email: '',
      car_id: 0,
      city_id: '',
      pickup_datetime: '',
      return_datetime: '',
      duration_days: 0,
      duration_hours: 0,
      total_price: 0,
      price_per_day: 0,
      title: { th: '', en: '' },
      image: '',
      category: { id: 0, th: '', en: '' },
      id: 0
    }
  };
  static contextType = ContextType;
  componentDidMount() {
    this.loadCities();
  }

  loadCities = () => {
    axios
      .get('/api/cities', {
        params: {
          with_cars: true
        }
      })
      .then(({ data }) => this.setState({ cities: data, isLoading: false }));
  };

  onSubmit = (data: FormSubmitValues) => {
    axios
      .post('/api/bookings', data)
      .then((resp) => {
        trackFormSubmit(CWDFormStatus.Success, data, resp.data.id);
        this.setState({ response: resp.data, isSuccess: true });
        this.scrollToForm();
      })
      .catch((error) => {
        trackFormSubmit(CWDFormStatus.Fail, data);
        console.log(error);
      });
  };

  scrollToForm = () => {
    setTimeout(() => {
      document.getElementById('cwd-header')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 50);
  };

  get panels() {
    const { prefix } = this.props.pageContext;
    const { frontmatter } = this.props.data.carWithDriver.edges[0].node;
    const panels: Panels[] = frontmatter.panels.reduce((p, c) => {
      return p.concat([
        {
          title: c[`title_${prefix}`],
          content: c[`content_${prefix}`],
          show_google_faq: c.show_google_faq
        }
      ]);
    }, new Array<Panels>());
    return panels;
  }

  render() {
    const { prefix } = this.props.pageContext;
    const { carWithDriver } = this.props.data;
    const { frontmatter } = carWithDriver.edges[0].node;
    const { cities, isLoading, isSuccess, response } = this.state;

    return (
      <Context>
        <Layout section={PageSection.CarWithDriver} prefix={prefix}>
          <SEO
            prefix={prefix}
            title={frontmatter[`title_${prefix}`]}
            description={frontmatter[`description_${prefix}`]}
            keywords={frontmatter[`keywords_${prefix}`]}
            disableAppBanner
          />
          <div>
            <Form prefix={prefix} cities={cities} onSubmit={(values) => this.onSubmit(values)}>
              {(form, onSelectCarID, onSelectCityID) => {
                return (
                  <Fragment>
                    <Header subTitle={frontmatter[`sub_title_${prefix}`]} />
                    <ValueProps />
                    {isLoading ? (
                      <Loading />
                    ) : (
                      <CarList
                        cities={cities}
                        prefix={prefix}
                        onSelectCar={(carID, carType, cityID) => {
                          onSelectCarID(carID, carType);
                          onSelectCityID(cityID);
                        }}
                      />
                    )}
                    <RentalStep />
                    <div>
                      <div id="driver-booking-form" className="bg-gray cwd-booking-form">
                        <div className="container">
                          <h2 id="cwd-header">จองรถเช่าพร้อมคนขับ</h2>
                          {isSuccess ? (
                            <SuccessBox bookingId={response.id.toString()} />
                          ) : (
                            <LazyLoad height={300}>{form}</LazyLoad>
                          )}
                        </div>
                      </div>
                    </div>
                  </Fragment>
                );
              }}
            </Form>
            <FAQ
              panels={this.panels}
              prefix={prefix}
              header="คำถามและข้อสงสัย ในการใช้ บริการเช่ารถยนต์ หรือ เช่ารถตู้ พร้อมคนขับ"
              sectionName={PageSection.CarWithDriver}
            />
            <Services prefix={prefix} sectionName={PageSection.CarWithDriver} />
            <Regions prefix={prefix} sectionName={PageSection.CarWithDriver} />
          </div>
        </Layout>
      </Context>
    );
  }
}

export default withTrans()(CarWithDriverPage);
