import { FunctionComponent } from 'react';
import './style.scss';

export const CarWithDriverValueProps: FunctionComponent = () => {
  return (
    <div id="prop" className="bg-gray cwd-prop">
      <div className="container">
        <div className="row align-items-baseline">
          <div className="col-md-3 pb-3 pb-md-0 cwd-item">
            <img src="/assets/shares/d-icon-1.svg" alt="รถเช่าคุณภาพมาตรฐาน พร้อมให้บริการ" />
            <p>รถเช่าคุณภาพมาตรฐาน พร้อมให้บริการ</p>
          </div>
          {/* end item */}
          <div className="col-md-3 pb-3 pb-md-0 cwd-item">
            <img src="/assets/shares/d-icon-2.svg" alt="พนักงานขับรถ มืออาชีพ" />
            <p>พนักงานขับรถ มืออาชีพ</p>
          </div>
          {/* end item */}
          <div className="col-md-3 pb-3 pb-md-0 cwd-item">
            <img src="/assets/shares/d-icon-3.svg" alt="ราคารวมบริการ 9ชั่วโมง/วัน" />
            <p>ราคารวมบริการ 9ชั่วโมง/วัน</p>
          </div>
          {/* end item */}
          <div className="col-md-3 pb-3 pb-md-0 cwd-item">
            <img src="/assets/shares/d-icon-4.svg" alt="มีเจ้าหน้าที่พร้อมดูแลคุณ ตลอดการเดินทาง" />
            <p>มีเจ้าหน้าที่พร้อมดูแลคุณ ตลอดการเดินทาง</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarWithDriverValueProps;
