/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Component, Fragment, FunctionComponent } from 'react';
import { withFormik, Field } from 'formik';
import { string as yupString, object as yupObject, date as yupDate } from 'yup';
import moment from 'moment';
import axios from 'axios';
import Modal from 'rc-dialog';
import Context from '@ui/components/context/';
import {
  CarWithDriverFormProps,
  CarWithDriverFormState,
  BookingDetail,
  BookingFormProps,
  FormValues
} from './interface';
import CWDPicker from './search-box';
import SummaryBox from './summary-box';
import TermsAndConditions from './term';
import { API_CAR_WITH_DRIVER } from '@config';
import { trackProvinceSelect, trackSelectCar, trackTermToggle, trackFormSubmit, CWDFormStatus } from './tracking';
import './style.scss';

moment().locale('Asia/Bangkok');
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

axios.defaults.baseURL = API_CAR_WITH_DRIVER;

class CarWithDriverForm extends Component<CarWithDriverFormProps, CarWithDriverFormState> {
  state = {
    pricing: {
      duration_days: 0,
      duration_hours: 0,
      price_per_day: 0,
      total_price: 0,
      pickup_datetime: moment().add(1, 'days').format('YYYY-MM-DD 10:00'),
      return_datetime: moment().add(3, 'days').format('YYYY-MM-DD 10:00'),
      city: '',
      bookability: {
        bookable: false,
        code: null
      }
    },
    isLoadingPricing: false,
    isOpenTerms: false
  };

  componentDidUpdate(prevProps: CarWithDriverFormProps) {
    if (prevProps.isSubmitting !== this.props.isSubmitting) {
      if (this.props.isSubmitting === true) return;
      if (Object.keys(this.props.errors).length > 0) trackFormSubmit(CWDFormStatus.Fail, this.props.values);
    }
  }
  onSelectCarID = async (carID: number, carType: string) => {
    await this.props.setFieldValue('car_id', carID);
    await this.props.setFieldValue('car_type', carType);
    this.loadPricing();
  };
  onSelectCityID = (cityID: string) => {
    this.props.setFieldValue('city_id', cityID);
  };
  onPickerDate = () => {
    const { car_type, car_id } = this.props.values;
    if (car_id) {
      this.onSelectCarID(car_id, car_type);
    }
  };

  loadPricing = (option = {}) => {
    const values = {
      ...this.props.values,
      ...option
    };
    const pickup_datetime = `${values.pickup_date} ${values.pickup_time}`;
    const return_datetime = `${values.return_date} ${values.return_time}`;
    const car_id = values.car_id;
    const params = {
      pickup_datetime,
      return_datetime,
      car_id
    };
    this.setState({ isLoadingPricing: true });
    axios
      .get('/api/pricing', {
        params
      })
      .then(({ data }) => {
        this.setState({
          pricing: {
            ...data,
            city: data.city[this.props.prefix]
          },
          isLoadingPricing: false
        });
      });
  };

  onToggleTerms = () => {
    this.setState({ isOpenTerms: !this.state.isOpenTerms });
  };
  renderSubmitBox = (onClick: () => void, isSubmitting: boolean) => {
    return (
      <div className="cwd-submit-box">
        <div className="cwd-form-sumit-term">
          ข้าพเจ้าอ่านและได้ยอมรับ{' '}
          <button
            type="button"
            className="read-more btn btn-link"
            onClick={(e) => {
              this.onToggleTerms();
              trackTermToggle(e);
            }}
            data-event-label="terms"
          >
            ข้อกำหนดและนโยบาย <i className="icon-link" />
          </button>{' '}
          ในการจองนี้
        </div>
        <button
          className="btn btn-primary btn-sm cwd-form-sumit-btn"
          type="submit"
          onClick={() => onClick()}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'กำลังทำการจอง...' : 'ทำการจอง'}
        </button>
      </div>
    );
  };

  handleOnSelectProvince = (e: any) => {
    const { cities, prefix } = this.props;
    const city = cities.filter((c) => c.id === parseInt(e.target.value))[0];
    trackProvinceSelect(city.name[prefix]);
    this.onSelectCityID(e.target.value);
  };

  render() {
    const { touched, errors, isSubmitting, handleSubmit, cities, prefix, values, setFieldValue } = this.props;
    const { pricing } = this.state;
    return (
      <Context>
        <Fragment>
          {this.props.children(
            <div className="row">
              <div className="col-12 col-lg-8">
                <form onSubmit={handleSubmit}>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <p>
                        <span className="badge badge-pill badge-primary">1</span> เลือกจังหวัด
                      </p>
                      <div className="selectContainer">
                        <Field
                          as="select"
                          name="city_id"
                          placeholder="city_id"
                          className={`form-control ${touched.city_id && errors.city_id ? 'error-field' : ''}`}
                          id="province"
                          onChange={(e: any) => this.handleOnSelectProvince(e)}
                          value={values.city_id}
                        >
                          <option disabled value="">
                            กรุณาเลือกจังหวัด
                          </option>
                          {cities.map((city) => (
                            <option key={city.name[prefix]} value={city.id}>
                              {city.name[prefix]}
                            </option>
                          ))}
                        </Field>
                        <label className="error-text">
                          {touched.city_id && errors.city_id && <div>{errors.city_id}</div>}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-12">
                      <p>
                        <span className="badge badge-pill badge-primary">2</span> ระบุวันเดินทาง
                      </p>
                    </div>
                    <CWDPicker
                      prefix={prefix}
                      bookingBegin={`${values.pickup_date} ${values.pickup_time}`}
                      bookingEnd={`${values.return_date} ${values.return_time}`}
                      pickerSubmit={(val) => {
                        const [pickup_date, pickup_time] = val.bookingBegin.split(' ');
                        const [return_date, return_time] = val.bookingEnd.split(' ');
                        setFieldValue('pickup_date', pickup_date);
                        setFieldValue('pickup_time', pickup_time);
                        setFieldValue('return_date', return_date);
                        setFieldValue('return_time', return_time);
                        this.onPickerDate();
                      }}
                    />
                  </div>
                  <div className="form-row">
                    <div className="col-12">
                      <p>
                        <span className="badge badge-pill badge-primary">3</span> รุ่นรถที่ต้องการ
                      </p>
                    </div>
                    {values.city_id !== '' ? (
                      <div className="col-12 car-selection">
                        <div className="row">
                          {cities
                            .filter((city) => city.id.toString() === values.city_id)[0]
                            .cars.map((car) => {
                              return (
                                <div
                                  className="col-6 col-lg-3 item"
                                  key={car.id}
                                  onClick={() => {
                                    this.onSelectCarID(car.id, car.category['th']);
                                    trackSelectCar(car.title[prefix]);
                                  }}
                                >
                                  <div className={`shadow ${values.car_id === car.id ? 'selected' : ''}`}>
                                    <div>
                                      <img src={car.image} alt={car.title[prefix]} className="img-fluid" />
                                      <p>
                                        <strong>{car.title[prefix]}</strong> <br />
                                        ราคา {car.price_per_day}/ วัน
                                      </p>
                                      <i className={`icon-ok-circle ${values.car_id === car.id ? 'selected' : ''}`} />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      <div className="col-12 empty">
                        <img src="/assets/shares/click.svg" alt="..." />
                        <p>โปรดเลือกจังหวัดและระบุวันเดินทางเพื่อเลือกรถ</p>
                      </div>
                    )}
                  </div>
                  <SideBox cityName={this.state.pricing.city} {...values} bookingDetail={pricing} className="mobile" />
                  <div className="form-row">
                    <div className="col-12">
                      <p>
                        <span className="badge badge-pill badge-primary">4</span> ข้อมูลติดต่อ
                      </p>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="fullName">ชื่อ-นามสกุล</label>
                      <Field
                        className={`form-control ${touched.full_name && errors.full_name ? 'error-field' : ''}`}
                        type="text"
                        name="full_name"
                        placeholder="กรอกชื่อ-นามสกุล"
                        id="full_name"
                      />
                      <label className="error-text">
                        {touched.full_name && errors.full_name && <div>{errors.full_name}</div>}
                      </label>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="phoneContact">เบอร์โทรศัพท์</label>
                      <Field
                        className={`form-control ${touched.phone_number && errors.phone_number ? 'error-field' : ''}`}
                        type="text"
                        name="phone_number"
                        placeholder="กรอกเบอร์โทรศัพท์"
                        id="phone_number"
                      />
                      <label className="error-text">
                        {touched.phone_number && errors.phone_number && <div>{errors.phone_number}</div>}
                      </label>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="eMail">อีเมล</label>
                      <Field
                        className={`form-control ${touched.email && errors.email ? 'error-field' : ''}`}
                        type="email"
                        name="email"
                        placeholder="กรอกอีเมล"
                        id="email"
                      />
                      <label className="error-text">{touched.email && errors.email && <div>{errors.email}</div>}</label>
                    </div>
                  </div>
                  <div className="d-lg-none">{this.renderSubmitBox(handleSubmit, isSubmitting)}</div>
                </form>
              </div>
              <div className="d-none d-lg-block col-lg-4">
                <div style={{ position: 'sticky', top: 0 }}>
                  <SideBox cityName={this.state.pricing.city} {...values} bookingDetail={pricing} className="desktop">
                    {this.renderSubmitBox(handleSubmit, isSubmitting)}
                  </SideBox>
                  <p className="contacts-box">ติดปัญหาหรือมีข้อสงสัย โทร. 02-038-5222</p>
                </div>
              </div>
            </div>,
            // Callback Function
            this.onSelectCarID,
            this.onSelectCityID
          )}
          <Modal
            closable
            destroyOnClose
            visible={this.state.isOpenTerms}
            onClose={this.onToggleTerms}
            animation="fade"
            maskAnimation="fade"
          >
            <div className="pt-3 pb-3">
              <TermsAndConditions />
            </div>
          </Modal>
        </Fragment>
      </Context>
    );
  }
}
interface SideBoxProps extends FormValues {
  cityName: string;
  bookingDetail: BookingDetail;
  children?: React.ReactNode;
  className?: string;
}
const SideBox: FunctionComponent<SideBoxProps> = ({ bookingDetail, cityName, children, className }) => {
  return (
    <div className={`side-box bg-light ${className}`}>
      <div className="side-box__detail">
        <div>
          <label>รับรถ/คืนรถที่</label>
          <div>{cityName}</div>
        </div>
        <div>
          <label>วันเดินทาง</label>
          <div>
            {moment(bookingDetail.pickup_datetime).format('DD MMM YYYY HH:mm')} -{' '}
            {moment(bookingDetail.return_datetime).format('DD MMM YYYY HH:mm')}
          </div>
        </div>
      </div>
      <SummaryBox durationDays={bookingDetail.duration_days} totalPrice={bookingDetail.total_price} />
      {children}
    </div>
  );
};

const schema = () => {
  return yupObject().shape({
    full_name: yupString().min(7, 'กรุณากรอกชื่อ - นามสกุล').required('กรุณากรอกชื่อ - นามสกุล'),
    phone_number: yupString()
      .min(10, 'เบอร์โทรศัพท์ไม่ถูกต้อง')
      .matches(phoneRegExp, 'เบอร์โทรศัพท์ไม่ถูกต้อง')
      .required('กรุณากรอกเบอร์โทรศัพท์'),
    email: yupString().email('อีเมลไม่ถูกต้อง').required('กรุณากรอกอีเมล'),
    car_id: yupString().required(),
    city_id: yupString().required('โปรดเลือกจังหวัด'),
    pickup_date: yupDate().required(),
    pickup_time: yupString().required(),
    return_date: yupDate().required(),
    return_time: yupString().required()
  });
};

const BookingForm = withFormik<BookingFormProps, FormValues>({
  mapPropsToValues: () => {
    return {
      full_name: '',
      phone_number: '',
      email: '',
      car_id: 0,
      city_id: '',
      car_type: '',
      pickup_date: moment().add(1, 'days').format('YYYY-MM-DD'),
      pickup_time: '10:00',
      return_date: moment().add(3, 'days').format('YYYY-MM-DD'),
      return_time: '10:00'
    };
  },

  validationSchema: schema,
  handleSubmit: (values, formikBag) => {
    const submitValue = {
      ...values,
      pickup_datetime: `${values.pickup_date} ${values.pickup_time}`,
      return_datetime: `${values.return_date} ${values.return_time}`
    };
    delete submitValue.pickup_date;
    delete submitValue.pickup_time;
    delete submitValue.return_date;
    delete submitValue.return_time;
    // trackFormSubmit(CWDFormStatus.Success, submitValue);
    formikBag.props.onSubmit(submitValue);
  }
})(CarWithDriverForm);

export default BookingForm;
