import { FunctionComponent } from 'react';
import { CWDCar } from '@templates/car-with-driver/interface';
import { trackSelectCar } from './tracking';
interface CarCardProps {
  car: CWDCar;
  prefix: string;
  onSelectCar: (carID: number) => void;
}

export const CarCard: FunctionComponent<CarCardProps> = ({ car, prefix, onSelectCar }) => {
  const handleOnClickCarCard = (selectedCar: CWDCar) => {
    console.log(selectedCar);
    trackSelectCar(selectedCar.title[prefix]);
    onSelectCar(selectedCar.id);
  };
  return (
    <div className="card">
      <div className="card-img">
        <a>
          <img
            data-src={car.image}
            src="/assets/shares/loading.svg"
            className="card-img-top lazyload"
            alt={car.title[prefix]}
          />
        </a>
      </div>
      <div className="card-body text-center">
        <div>
          <h4 className="card-title">{car.title[prefix]}</h4>
          <p className="card-text">
            <span className="title">
              <i className="icon-user"></i>
              {car.seats} ที่นั่ง
            </span>
            <span>เริ่มต้น {Number(car.price_per_day).toLocaleString()} บาท/วัน</span>
          </p>
          <button className="btn btn-primary btn-sm" onClick={() => handleOnClickCarCard(car)}>
            จองคันนี้
          </button>
        </div>
      </div>
    </div>
  );
};

export default CarCard;
