import { createRef, PureComponent, Fragment } from 'react';
import moment from 'moment';
import { ContextType } from '@ui/components/context';
import loadable from '@loadable/component';
import LoadingBox from '@shares/loading';
import { TimePickerProps } from '@ui/components/time-picker/interface';
import { DatePickerProps } from '@ui/components/date-picker/interface';
import { trackSearchDate, trackSearchTime } from './tracking';

const DatePicker = loadable<DatePickerProps>(() => import(`@ui/components/date-picker`), {
  fallback: <LoadingBox />
});
const TimePicker = loadable<TimePickerProps>(() => import(`@ui/components/time-picker`), {
  fallback: <LoadingBox />
});

export interface CWDSearchBoxState {
  bookingBegin: string;
  bookingEnd: string;
  focused: string;
}

export interface Range {
  bookingBegin: string;
  bookingEnd: string;
}
export interface CWDSearchBoxProps {
  bookingBegin: string;
  bookingEnd: string;
  prefix: string;
  // eslint-disable-next-line no-unused-vars
  pickerSubmit: (val: Range) => void;
}

const format = 'YYYY-MM-DD HH:mm';
class CWDSearchBox extends PureComponent<CWDSearchBoxProps, CWDSearchBoxState> {
  constructor(props: CWDSearchBoxProps) {
    super(props);
    this.state = {
      bookingBegin: this.props.bookingBegin,
      bookingEnd: this.props.bookingEnd,
      focused: ''
    };
  }
  static contextType = ContextType;
  // context!: ContextType<typeof ContextType>;
  datePickerRef = createRef<HTMLDivElement>();

  componentDidMount() {
    window.addEventListener('resize', (e) => this.handleResponsive(e), false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', (e) => this.handleResponsive(e), false);
  }

  onClickDatePicker = (focused: string) => {
    const { isMobile } = this.context;
    const element = document.getElementById('mainmenu-navbar');
    if (!element) return;
    this.setState({ focused: focused }, () => {
      if (!isMobile) {
        this.handleClickDatePicker();
      }

      if (isMobile) {
        element.style.zIndex = '100';
      } else {
        element.style.zIndex = '1800';
      }
    });
  };

  handleClickDatePicker = () => {
    if (this.state.focused !== '') {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }
  };

  handleOutsideClick = (e: any) => {
    if (this.datePickerRef.current && this.datePickerRef.current.contains(e.target)) return;
    this.setState({ focused: '' });
  };

  handleResponsive = (e: any) => {
    const { isMobile } = this.context;
    const element = document.getElementById('mainmenu-navbar');
    if (e.target === null) return;
    if (!element) return;

    if (isMobile) {
      element.style.zIndex = '100';
    } else {
      element.style.zIndex = '1800';
    }
  };

  onSubmitDatePicker = (range: Range) => {
    this.setState(
      {
        bookingBegin: range.bookingBegin,
        bookingEnd: range.bookingEnd,
        focused: ''
      },
      () => {
        this.props.pickerSubmit({
          bookingBegin: range.bookingBegin,
          bookingEnd: range.bookingEnd
        });
      }
    );
  };

  onFieldSubmit = (range: Range) => {
    const nextRange = this.datetimeAlign(range);
    this.setState(
      {
        ...nextRange,
        focused: ''
      },
      () => {
        this.props.pickerSubmit({
          ...nextRange
        });
      }
    );
  };

  datetimeAlign = (range: Range): Range => {
    const state = {
      bookingBegin: range.bookingBegin,
      bookingEnd: range.bookingEnd
    };
    let bookingBegin = moment(range.bookingBegin, format);
    const bookingEnd = moment(range.bookingEnd, format);

    if (bookingBegin.isBefore(moment())) {
      state.bookingBegin = bookingBegin.format(`YYYY-MM-DD ${moment().hours() + 1}:00`);
      bookingBegin = moment(state.bookingBegin, format);
    }

    if (bookingBegin.format('YYYY-MM-DD') === bookingEnd.format('YYYY-MM-DD')) {
      if (parseInt(bookingEnd.format('HH'), 10) - parseInt(bookingBegin.format('HH'), 10) <= 4) {
        state.bookingEnd = bookingBegin.format(
          `YYYY-MM-DD ${parseInt(bookingBegin.format('HH'), 10) + 4}:${bookingBegin.format('mm')}`
        );
      }
    }
    return state;
  };

  render() {
    const { bookingBegin, bookingEnd, focused } = this.state;

    return (
      <div className="picker-cwd col-12">
        <div className="picker-cwd-container form-row" ref={this.datePickerRef}>
          <DatePicker
            bookingBegin={bookingBegin}
            bookingEnd={bookingEnd}
            focused={focused}
            isActive={focused === 'bookingBegin' || focused === 'bookingEnd'}
            onSubmit={(range) => this.onSubmitDatePicker(range)}
            onClose={() => this.onClickDatePicker('')}
          >
            <div className="form-group col-lg-6 box">
              <label>วันและเวลารับรถ</label>
              <div className="form-row ">
                <div
                  className="col-8"
                  onClick={(e) => {
                    this.onClickDatePicker('bookingBegin');
                    trackSearchDate(e);
                  }}
                  data-event-label="pickup_date"
                >
                  <div className="children">{moment(bookingBegin, format).format('DD MMM YYYY')}</div>
                  <i className="icon-calendar" />
                </div>
                <div className="col-4" data-event-label="pickup_time" onClick={(e) => trackSearchTime(e)}>
                  <TimePicker
                    id="timepicker_booking_begin"
                    bookingBegin={bookingBegin}
                    bookingEnd={bookingEnd}
                    onChange={this.onFieldSubmit}
                    isBookingBegin={true}
                  >
                    {(picker) => (
                      <Fragment>
                        <div className="children">{picker}</div>
                        <i className="icon-down" />
                      </Fragment>
                    )}
                  </TimePicker>
                </div>
              </div>
            </div>
            <div className="form-group col-lg-6 box">
              <label htmlFor="dateTo">วันและเวลาคืนรถ</label>
              <div className="form-row">
                <div
                  className="col-8"
                  onClick={(e) => {
                    this.onClickDatePicker('bookingEnd');
                    trackSearchDate(e);
                  }}
                  data-event-label="return_date"
                >
                  <div className="children">{moment(bookingEnd, format).format('DD MMM YYYY')}</div>
                  <i className="icon-calendar" />
                </div>
                <div className="col-4" data-event-label="return_time" onClick={(e) => trackSearchTime(e)}>
                  <TimePicker
                    id="timepicker_booking_end"
                    bookingBegin={bookingBegin}
                    bookingEnd={bookingEnd}
                    onChange={this.onFieldSubmit}
                    isBookingBegin={false}
                  >
                    {(picker) => (
                      <Fragment>
                        <div className="children">{picker}</div>
                        <i className="icon-down" />
                      </Fragment>
                    )}
                  </TimePicker>
                </div>
              </div>
            </div>
          </DatePicker>
        </div>
      </div>
    );
  }
}

export default CWDSearchBox;
