import { PureComponent } from 'react';

interface SummaryBoxProps {
  durationDays: number;
  totalPrice: number;
}
class SummaryBox extends PureComponent<SummaryBoxProps> {
  render() {
    const { durationDays, totalPrice } = this.props;
    return (
      <div className="side-box__pricing">
        <div className="underline">
          <span>รวมค่าเช่ารถสำหรับ {durationDays} วัน</span>
          <span>฿{totalPrice.toLocaleString()}</span>
        </div>
        <div className="underline">
          <span>ค่ารับ-ส่งรถ</span>
          <span className="free">ฟรี</span>
        </div>
        <div className="underline bold">
          <span>ราคารวม</span>
          <span>฿{totalPrice.toLocaleString()}</span>
        </div>
        <div style={{ marginTop: 15 }}>
          <div>
            <b>อัตราข้างต้นรวม</b>
          </div>
          <div className="value-props">
            <div>
              <img
                style={{ marginTop: 4 }}
                data-src="/assets/pages/car-with-driver/car_sm.svg"
                src="/assets/shares/loading.svg"
                className="lazyload"
                alt="rental car"
              />
              <div>รถเช่า</div>
            </div>
            <div>
              <img
                data-src="/assets/pages/car-with-driver/driver_sm.svg"
                src="/assets/shares/loading.svg"
                className="lazyload"
                alt="car with driver"
              />
              <div>คนขับ</div>
            </div>
            <div>
              <img
                data-src="/assets/pages/car-with-driver/9hours_sm.svg"
                src="/assets/shares/loading.svg"
                className="lazyload"
                alt="บริการ 9ชม/วัน"
              />
              <div>บริการ 9ชม/วัน</div>
            </div>
          </div>
        </div>
        <div>
          <div className="except">
            <b>อัตราข้างต้นนี้ไม่รวม</b> ค่าบริการนอกเวลาทำการ, ค่าน้ำมัน และค่าผ่านทางพิเศษ
          </div>
        </div>
      </div>
    );
  }
}

export default SummaryBox;
