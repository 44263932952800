import { FunctionComponent } from 'react';

export const TermsAndConditions: FunctionComponent = () => {
  return (
    <div className="cwd-terms">
      <h2>เงื่อนไข และข้อตกลง</h2>
      <ol>
        <li>
          <span>สิ่งที่รวมอยู่ในราคาเช่ารถ พร้อมคนขับ</span> <br />
          <p>
            ราคาเช่ารถตามจำนวนวันที่เลือก และค่าบริการคนขับเริ่มต้นวันละ 9 ชั่วโมง
            (โดยจะต้องใช้เริ่มใช้และสิ้นสุดการใช้รถภายในช่วงเวลาทำการ 8:00 ถึง 18:00 เท่านั้น)
          </p>
        </li>
        <li>
          <span>สิ่งที่ไม่รวมอยู่ในราคาเช่ารถ พร้อมคนขับ</span> <br />
          <p>
            ราคาที่แสดงนั้นจะยังไม่ครอบคลุมถึง ค่าน้ำมัน, ค่าทางด่วน (ถ้ามี), ค่าที่จอดรถ (ถ้ามี) ,
            ค่าบริการกรณีมีให้บริการล่วงเวลา และค่าบริการกรณีมีการบริการนอกเวลา
            รวมไปถึงค่าที่พักพนักงานขับกรณีมีการค้างแรมนอกสถานที่ โดยสามารถดูค่าบริการของการบริการต่างๆ จากตารางข้างล่าง
          </p>
          <table className="table table-bordered mb-0">
            <thead>
              <tr>
                <th scope="col">ประเภทของค่าบริการเพิ่มเติม</th>
                <th className="fee" scope="col">
                  ค่าบริการ
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ค่าน้ำมัน (ตลอดการเดินทาง)</td>
                <td>ตามจริง</td>
              </tr>
              <tr>
                <td>ค่าทางด่วน (ถ้ามี)</td>
                <td>ตามจริง</td>
              </tr>
              <tr>
                <td>ค่าที่จอดรถ (ถ้ามี)</td>
                <td>ตามจริง</td>
              </tr>
              <tr>
                <td>ค่าบริการกรณีให้บริการเกินระยะเวลาที่กำหนด (9 ชั่วโมง/วัน)</td>
                <td>100-400 บาท/ชม.</td>
              </tr>
              <tr>
                <td>ค่าบริการกรณีเริ่มหรือสิ้นสุดการใช้บริการนอกเวลาทำการ (8:00-18:00)</td>
                <td>100-400 บาท/ชม.</td>
              </tr>
              <tr>
                <td>ค่าที่พักของพนักงานขับรถ</td>
                <td>เริ่มต้น 400 บาท/คืน</td>
              </tr>
            </tbody>
          </table>
          <small>*หรือชำระค่าที่พักตามจริง</small>
        </li>
        <li>
          <span>ระยะเวลาในการให้บริการ </span> <br />
          <p>
            การเช่ารถพร้อมคนขับ มีระยะเวลาในการให้บริการ 9 ชั่วโมง โดยจะคิดเป็นการเช่า 1 วัน
            หากมีความจำเป็นต้องใช้บริการเกินระยะเวลาที่กำหนด สามารถชำระค่านอกเวลาให้บริการ (OT สำหรับผู้ขับ)
            เพิ่มเติมได้โดยตรงกับพนักงานขับรถตามอัตราที่ทางผู้ให้เช่าเป็นผู้กำหนด
          </p>
        </li>
        <li>
          <span>การมัดจำและการชำระเงิน</span> <br />
          <p>
            หลังทำการจอง ผู้เช่าจะต้องชำระค่าล็อคคิวรถเป็นจำนวนเงิน 500-1,000 บาท
            (โดยอัตราดังกล่าวขึ้นอยู่กับผู้ให้เช่าเป็นผู้กำหนด) หากทำการจองในช่วงฤดูกาลท่องเที่ยว
            (เดือนตุลาคมถึงเดือนกุมภาพันธ์) หรือช่วงเทศกาลและวันหยุดนักขัตฤกษ์
            ผู้ให้เช่าอาจมีการเรียกเก็บค่าล็อคคิวรถที่เพิ่มขึ้นจากอัตราดังกล่าว โดยค่าล็อคคิวรถจะถูกนำไปหักจากราคาเช่ารถ
            ซึ่งในวันรับรถผู้เช่าชำระเพียงค่าเช่าส่วนที่เหลือ
          </p>
        </li>
        <li>
          <span>การยกเลิก</span> <br />
          <p>
            หากต้องการยกเลิกการจอง ผู้เช่าสามารถแจ้งความประสงค์กับทางผู้ให้เช่าได้โดยตรง
            โดยจะได้รับเงินคืนก็ต่อเมื่อผู้เช่าได้ทำการแจ้งยกเลิกล่วงหน้าอย่างน้อย 72 ชั่วโมง
            (โดยนับจากวันและเวลาที่เริ่มการเช่า)
            หากไม่เข้าเงื่อนไขดังกล่าวผู้ให้เช่าขอสงวนสิทธิ์ในการคืนค่าล็อคคิวรถทุกกรณี
          </p>
        </li>
        <li>
          <span>ความเสียหายภายในตัวรถ</span> <br />
          <p>
            หากมีความเสียหายเกิดขึ้นภายในตัวรถอันเกิดจากการกระทำของผู้เช่า อาทิ อุปกรณ์ในรถยนต์ เบาะรถยนต์
            ชำรุดหรือสูญหาย ในระหว่างการใช้บริการ ทางผู้เช่าจะต้องจ่ายค่าปรับทั้งหมดตามรายการที่ผู้ให้เช่าเรียกเก็บ
            หรือยึดตามเกณฑ์ราคาค่าปรับที่ศูนย์บริการรถยนต์ยี่ห้อนั้นๆกำหนดขึ้นและค่าบริการตามจริง
          </p>
        </li>
        <li>
          <span>ทรัพย์สินสูญหาย</span> <br />{' '}
          <p>
            ผู้ให้เช่าไม่มีความรับผิดชอบใดๆ ในกรณีที่ผู้เช่าลืมทรัพย์สินไว้ในรถที่เช่าทั้งสิ้น
            หลังการเดินทางเสร็จสิ้นแล้ว
            ไดรฟ์ฮับขอสงวนสิทธิ์ในการไม่รับผิดชอบความเสียหายหรือสูญหายของทรัพย์สินที่เกิดขึ้นจากความประมาทของผู้โดยสารที่ทำทรัพย์สินหล่นหรือลืมไว้ในรถของคนผู้ให้เช่า
          </p>
        </li>
      </ol>
    </div>
  );
};

export default TermsAndConditions;
