import { FunctionComponent } from 'react';
import './success.scss';

const Success: FunctionComponent<{ bookingId: string }> = ({ bookingId }) => (
  <div className="bg-gray cwd-success">
    <div>
      <div>
        <img src="/assets/pages/car-with-driver/file.svg" alt="sucess" />
        <p className="header">ระบบได้รับคำขอจองของคุณแล้ว!</p>
        <p>
          หมายเลขการจองของคุณคือ #D{bookingId} <br /> <br />
          กรุณารอการติดต่อกลับจากเจ้าหน้าที่เพื่อยืนยันการจองภายใน 15-30 นาที
          <br />
          (เวลาทำการ 10:00น. - 18:00น. นอกเหนือเวลาทำการเจ้าหน้าที่จะติดต่อกลับภายในวันทำการถัดไป)
        </p>
      </div>
    </div>
    <div>
      <p>หากติดปัญหาหรือมีข้อสงสัย โทร. 02-038-5222</p>
    </div>
  </div>
);

export default Success;
