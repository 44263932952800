import { FunctionComponent, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import Background from '@shares/background';
import { trackBookBtn } from './tracking';
interface CarWithDriverHeaderProps {
  subTitle: string;
}
export const CarWithDriverHeader: FunctionComponent<CarWithDriverHeaderProps> = (props: CarWithDriverHeaderProps) => {
  const onClickCWDBtn = (): void => {
    const formRef = document.getElementById('driver-booking-form');
    if (!formRef) return undefined;
    trackBookBtn();
    formRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };
  const data = useStaticQuery<{ file: IGatsbyImageData }>(graphql`
    query CWDhero {
      file(absolutePath: { regex: "/car-with-driver-header.jpg/" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
    }
  `);

  const heroSrc = useMemo(() => {
    return data.file;
  }, [data]);

  return (
    <Background src={heroSrc} id="car-with-driver-section-bg">
      <div className="banner-container">
        <div className="overlay" />
        <div className="content-section">
          <div className="heading-text">
            <h1>บริการเช่ารถ พร้อมคนขับ</h1>
            <h4>{props.subTitle}</h4>
          </div>
          <div className="btn btn-primary" onClick={(): void => onClickCWDBtn()}>
            เลือกรถและเช็คราคา
          </div>
        </div>
      </div>
    </Background>
  );
};

export default CarWithDriverHeader;
