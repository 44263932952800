import { sendToDataLayer, getDefaultEventCategory, PageSection, convertToDateAndTime, sha256 } from '@utils';
import { FormSubmitValues } from './interface';

export const trackProvinceSelect = (provinceName: string) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.CarWithDriver),
    event_action: 'select_form',
    event_label: `province_${provinceName}`
  });
};

export const trackSelectCar = (carModel: string) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.CarWithDriver),
    event_action: 'select_form',
    event_label: `car_${carModel.toLowerCase().replace(/ /g, '_')}`
  });
};

export const trackTermToggle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  sendToDataLayer(e, {
    event_category: getDefaultEventCategory(PageSection.CarWithDriver)
  });
};

export const trackSearchTime = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  sendToDataLayer(e, {
    event_category: getDefaultEventCategory(PageSection.CarWithDriver)
  });
};

export const trackSearchDate = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  sendToDataLayer(e, {
    event_category: getDefaultEventCategory(PageSection.CarWithDriver)
  });
};
export enum CWDFormStatus {
  Success = 'success',
  Fail = 'not_success'
}

export const trackFormSubmit = async (status: CWDFormStatus, bookData: FormSubmitValues, cwdID?: string) => {
  const {
    pickup_date,
    pickup_time,
    return_date,
    return_time,
    email,
    phone_number,
    car_type,
    pickup_datetime,
    return_datetime
  } = bookData;
  let encryptEmail = 'undefined';
  let encryptPhoneNumber = 'undefined';
  let bookingBegin = {
    date: 'undefined',
    time: 'undefined'
  };
  let bookingEnd = {
    date: 'undefined',
    time: 'undefined'
  };
  if (pickup_datetime && return_datetime) {
    bookingBegin = convertToDateAndTime(pickup_datetime);
    bookingEnd = convertToDateAndTime(return_datetime);
  } else {
    if (!pickup_date || !return_date) return;
    bookingBegin = convertToDateAndTime(`${pickup_date} ${pickup_time}`);
    bookingEnd = convertToDateAndTime(`${return_date} ${return_time}`);
  }

  if (email !== '') {
    await sha256(email).then((resp) => {
      encryptEmail = resp;
    });
  }
  if (phone_number !== '') {
    await sha256(phone_number).then((resp) => {
      encryptPhoneNumber = resp;
    });
  }
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.CarWithDriver),
    event_action: `drop_form_${status}`,
    event_label: `booking`,
    car_type: `${car_type.toLowerCase()}`,
    pickup_date: `${bookingBegin.date}`,
    pickup_time: `${bookingBegin.time}`,
    return_date: `${bookingEnd.date}`,
    return_time: `${bookingEnd.time}`,
    encrypt_email: `${encryptEmail}`,
    encrypt_phonenumber: `${encryptPhoneNumber}`,
    rental_withdrive_id: cwdID ? `D${cwdID}` : 'undefined'
  });
};
